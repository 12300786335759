import {AxiosResponse} from 'axios'
import {ApplicationModel, Dto, Field} from '../models/application_model'
import cgaasManifest from './microServices/cgaas-manifest'
import {DataSourceModel} from '../models/dataSource_model'
import {string} from 'yup'

const createDataSource = (data: DataSourceModel): Promise<DataSourceModel> => {
  return cgaasManifest
    .post('Create/datasource', data)
    .then((response: AxiosResponse<DataSourceModel>) => response.data)
}

const updateDataSource = (data: DataSourceModel): Promise<DataSourceModel> => {
  return cgaasManifest
    .put('Update/datasource?userId=' + data?.userId + '&datasourceId=' + data?.datasourceId, data)
    .then((response: AxiosResponse<DataSourceModel>) => response.data)
}

const createDefaultDataSource = (userId: string): Promise<any> => {
  return cgaasManifest
    .post('/Create/default/datasource?userId=' + userId)
    .then((response: AxiosResponse<any>) => response.data)
}

const getAllDataSourcesByUserId = (userId: string): Promise<DataSourceModel[]> => {
  return cgaasManifest
    .get('GetAll/datasource/userId' + '?userId=' + userId)
    .then((response: AxiosResponse<DataSourceModel[]>) => response.data)
}

const getAllDataSourcesByDBType = (userId: string, dbType: string): Promise<DataSourceModel[]> => {
  return cgaasManifest
    .get('GetAll/datasource/dbType' + '?userId=' + userId + '&dbType=' + dbType)
    .then((response: AxiosResponse<DataSourceModel[]>) => response.data)
}

const deleteDataSource = (data: DataSourceModel): Promise<any> => {
  return cgaasManifest
    .delete('Delete/datasource?' + 'datasourceId=' + data.datasourceId + '&userId=' + data?.userId)
    .then((response: AxiosResponse<any>) => response.data)
}

const updateDtoField = (
  data?: Field[],
  workspaceId?: string,
  appId?: string,
  dtoId?: string,
  updateField?: string,
): Promise<Field[]> => {
  return cgaasManifest
    .put(
      '/Update/dtoFields' + '?workspaceId=' + workspaceId + '&appId=' + appId + '&dtoId=' + dtoId + '&updatedField=' + updateField,
      data
    )
    .then((response: AxiosResponse<Field[]>) => response.data)
}

const findUserDefaultSettings = (userId: string | undefined): Promise<boolean> => {
  return cgaasManifest
    .get('/find/default/settings', {
      params: {userId},
    })
    .then((response: AxiosResponse<boolean>) => response.data)
    .catch((error) => {
      console.error('Error fetching user default settings:', error)
      throw error
    })
}

export {
  createDataSource,
  updateDataSource,
  getAllDataSourcesByDBType,
  getAllDataSourcesByUserId,
  updateDtoField,
  deleteDataSource,
  createDefaultDataSource,
  findUserDefaultSettings,
}
